footer {
    width: 100%;
    padding: 3rem 1.5rem 4rem;
    background: #6E07F3;
}


footer .footer_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

footer .footer_wrapper .top_footer  {
    margin-bottom: 30px;
}

footer .footer_wrapper .top_footer .logo {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
}

footer .footer_wrapper .top_footer .logo img {
    max-width: 70px;
}

footer .footer_wrapper .top_footer p {
    text-align: center;
    line-height: 1.4;
    color: white;
    font-size: 1.6rem;
    font-family: "eurostile";
}

footer .footer_wrapper .socials {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
}

footer .footer_wrapper .socials .social_icon_container {
    display: flex;
    align-items: center;
    justify-content:  center;
    padding: 6px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid rgba(255, 255, 255, 0.5);
    transition: all .3s ease-in-out;
}

footer .footer_wrapper .socials .social_icon_container .social_icon {
    font-size: 20px;
    color: white;
}

footer .footer_wrapper .socials .social_icon_container:hover {
    background: white;
    cursor: pointer;
}

footer .footer_wrapper .socials .social_icon_container:hover .social_icon {
    color: var(--theme-color);
}

footer .footer_wrapper .copyright {
    text-align: center;
    color: white;
}

footer .footer_wrapper .copyright p {
    display: flex;
    align-items: center;
}

footer .footer_wrapper .copyright p .copyright_icon {
    margin: 0px 0 -2px 3px;
}

