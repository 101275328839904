.page_wrapper {
    width: 100%;
}

.page_wrapper .cancel_icon_container {
    padding: 20px;
    width: 90%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    z-index: 5;
}

.page_wrapper .cancel_icon_container span {
    color: #141c3a;
    font-family: "eurostile", sans-serif;
    font-size: 20px;
    font-weight: 500;
}

.page_wrapper .cancel_icon_container .cancel_icon {
    font-size: 26px;
    color: #6E07F3;
    margin-bottom: -7px;
    margin-right: 5px;
}

.page_wrapper .avatar_container {
    padding: 30px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.page_wrapper .avatar_container img {
    max-width: 100px;
}

.page_wrapper .contact_form_wrapper {
    padding: 50px 30px;
    width: 100%;
}

.page_wrapper .contact_form_wrapper h1 {
    text-align: center;
    color: #141c3a;
    font-family: "eurostile", sans-serif;
    font-weight: 400;
    font-size: 2rem;
    line-height: 1.125;
    margin-bottom: 50px;
}

@media screen and (min-width: 1024px) {
    .page_wrapper .contact_form_wrapper h1 {
        font-size: 2.5rem;
    }
}

.page_wrapper .contact_form_wrapper form {
    width: 100%;
    margin: 0 auto;
    max-width: 45%;
}

@media screen and (max-width: 890px) {
    .page_wrapper .contact_form_wrapper form {
        max-width: 90%;
    }
}

@media screen and (max-width: 890px) {
    .page_wrapper .contact_form_wrapper form {
        max-width: 100%;
    }
}

.page_wrapper .contact_form_wrapper form .input_row {
    width: 100%;
    margin-bottom: 30px;
}


.page_wrapper .contact_form_wrapper form .input_row .input_item label {
    font-weight: 400;
    font-family: "eurostile", sans-serif;
    font-size: 1.2rem;
    color: rgba(41, 51, 71, 0.65);
    margin-bottom: .5em;
    display: block;
}

.page_wrapper .contact_form_wrapper form .input_row .input_item input {
    max-width: 400px;
    width: 100%;
    padding: 10px 20px;
    border: 2px solid #e6ecf8;
    border-radius: 4px;
    min-width: 300px;
    color: #141c3a;
    box-shadow: none;
}

.page_wrapper .contact_form_wrapper form .input_row .input_item input:focus {
    outline: none;
}

.page_wrapper .contact_form_wrapper form .input_row .input_item select {
    max-width: 400px;
    width: 100%;
    padding: 10px 20px;
    border: 2px solid #e6ecf8;
    border-radius: 4px;
    min-width: 300px;
    color: #141c3a;
    box-shadow: none;
}

.page_wrapper .contact_form_wrapper form .input_row .input_item select:focus {
    outline: none;
}

.page_wrapper .contact_form_wrapper form .input_row .input_item textarea {
    max-width: 650px;
    width: 100%;
    padding: 10px 20px;
    border: 2px solid #e6ecf8;
    border-radius: 4px;
    min-width: 300px;
    color: #141c3a;
    box-shadow: none;
}

.page_wrapper .contact_form_wrapper form .input_row .input_item textarea:focus {
    outline: none;
}

.page_wrapper .contact_form_wrapper form .input_row button {
    padding: 12px 20px;
    border-radius: 9999px;
    color: #6E07F3;
    border: 2px solid #6E07F3;
    background: transparent;
    font-weight: 400;
    height: auto;
    min-width: 150px;
    max-width: 200px;
}

.page_wrapper .contact_form_wrapper form .input_row button:hover {
    cursor: pointer;
}

.page_wrapper .contact_form_wrapper form .error {
    color: red;
    font-weight: 600;
    font-size: 14px;
}

@media screen and (max-width: 1200px) {
    .page_wrapper .contact_form_wrapper form {
        max-width: 60%;
    }
}

@media screen and (max-width: 1000px) {
    .page_wrapper .contact_form_wrapper form {
        max-width: 98%;
        margin: 0 auto;
    }

    .page_wrapper .contact_form_wrapper form .input_row {
        justify-content: center;
    }

    .page_wrapper .contact_form_wrapper form .input_row .textarea_item {
        max-width: 82%;
        margin: 0 auto;
    }

    .page_wrapper .contact_form_wrapper {
        padding: 30px 10px;
    }
}

@media screen and (max-width: 670px) {
    .page_wrapper .contact_form_wrapper form .input_row {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .page_wrapper .contact_form_wrapper form .textarea_row {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .page_wrapper .contact_form_wrapper form .input_row .input_item textarea {
        max-width: 300px;
    }

    .page_wrapper .contact_form_wrapper h1 {
        font-size: 26px;
    }
}

@media screen and (max-width: 450px) {
    .page_wrapper .contact_form_wrapper form .input_row .textarea_item {
        max-width: 100%;
    }  
}