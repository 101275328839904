.header_wrapper {
    width: 100%;
    min-height: 70px;
    padding: 20px 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.header_wrapper .logo {
    display: flex;
    align-items: center;
    justify-content: center;
}

.header_wrapper .logo img {
    max-width: 70px;
}

.header_wrapper ul {
    display: flex;
    align-items: center;
    gap: 20px;
}

.header_wrapper ul li {
    list-style: none;
}

.header_wrapper ul li a,
button {
    transition: all .2s ease-in-out;
    -webkit-transition: all .2s ease-in-out;
}

.header_wrapper ul li a {
    line-height: 1.5;
    padding: 8px 12px;
    font-size: 18px;
    color: #141c3a;
}

.header_wrapper ul li button {
    background-color: transparent;
    border-color: var(--theme-color);
    color: var(--theme-color);
    padding: 12px 16px;
    font-size: 18px;
    border-radius: 9999px;
}

.header_wrapper ul li a:hover {
    color: var(--theme-color);
    background-color: transparent;
}

.header_wrapper ul li button:hover {
    color: white;
    background: var(--theme-color);
    cursor: pointer;
}

.header_wrapper .burger {
    position: absolute;
    cursor: pointer;
    right: 5%;
    top: 6%;
    display: none;
}

.header_wrapper .burger .line {
    width: 20px;
    height: 4px;
    background-color: var(--theme-color);
    margin-top: 2px;
}

@media screen and (max-width: 860px) {
    .header_wrapper {
        width: 100%;
    }

    .header_wrapper ul li button {
        padding: 10px 12px;
        font-size: 16px;
    }
}

@media screen and (max-width: 768px) {
    .header_wrapper .logo {
        transition: all .3s ease-in-out;
    }
    
    .header_wrapper .burger {
        display: block;
    }

    .header_wrapper {
        height: 240px;
        transition: all .7s ease-in-out;
        padding: 10px 30px;
        flex-direction: column;
        align-items: center;
    }

    .header_wrapper ul {
        flex-direction: column;
        margin-top: 20px;
        justify-content: center;
        transition: all .3s ease-in-out;
    }

    .h-nav {
        height: 50px;
    }

    .v-class {
        opacity: 0;
        visibility: hidden;
    }

    .header_wrapper ul li button {
        padding: 6px 10px;
    }
}