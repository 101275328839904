.popup_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);   
}

.popup_overlay .popup_content {
    position: absolute;
    background: var(--theme-color);
    padding: 30px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 4px;
    color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.popup_overlay .popup_content  p {
    max-width: 300px;
    text-align: center;
    line-height: 1.5;
    font-family: "eurostile", sans-serif;
    font-size: 18px;
}

.popup_overlay .popup_content .close_btn {
    position: absolute;
    right: 5px;
    top: 10px;
    background: none;
    border: none;
    outline: none;
}

.popup_overlay .popup_content .check_icon {
    font-size: 50px;
}

.popup_overlay .popup_content .close_btn .close_icon {
    font-size: 24px;
    color: white;
}

.popup_overlay .popup_content .close_btn .close_icon:hover {
    cursor: pointer;
}