* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --theme-color: #6E07F3;
}

@font-face {
  font-family: "europa";
  src: url('./assets/fonts/EuropaNuovaRegular.ttf') format('truetype');
}

@font-face {
  font-family: "eurostile";
  src: url('./assets/fonts/eurostile.TTF') format('truetype');
}

body {
  font-family: "europa";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media screen and (min-width: 1408px) {
  .container:not(.is-max-desktop):not(.is-max-widescreen) {
      max-width: 1344px;
  }
}