.home_wrapper {
    width: 100%;
}

.home_wrapper .banner_section {
    padding: 20px 40px 0px 40px;
    width: 90%;
    margin: 0 auto;
}

.home_wrapper .banner_section .banner_tagline {
    margin-top: 60px;
    width: 100%;
    text-align: center;
}

.home_wrapper .banner_section .banner_tagline h1 {
    color: #141c3a;
    font-weight: 800;
    margin-bottom: 1rem;
}

.home_wrapper .banner_section .banner_tagline p {
    color: rgba(10, 10, 10, 0.9);
    margin-bottom: 1.5rem;
    line-height: 1.5;
}

.home_wrapper .banner_section .avatar_container,
.banner_image_container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.home_wrapper .banner_section .banner_tagline h1 {
    font-size: 44px;
}

.home_wrapper .banner_section .banner_tagline p {
    font-size: 24px;
}


.home_wrapper .banner_section .avatar_container img {
    width: 200px;
}

.home_wrapper .banner_section .banner_image_container img {
    max-width: 868px;
}

.home_wrapper .banner_section .avatar_container {
    margin-bottom: 6rem;
}


.home_wrapper .intro_section {
    width: 100%;
    background: var(--theme-color);
}

.home_wrapper .intro_section .intro_text_wrapper {
    max-width: 80%;
    text-align: center;
}

.home_wrapper .intro_section .intro_text_wrapper .title {
    width: 100%;
    text-align: center;
}

.home_wrapper .intro_section .intro_text_wrapper .title h1 {
    color: white;
    font-weight: 800px;
    margin-bottom: 1.5rem;
    font-family: "eurostile";
}

.home_wrapper .intro_section .intro_text_wrapper .intro_text p {
    color: white;
    line-height: 1.5;
    font-weight: 400;
    text-align: center;
}

.home_wrapper .skills_section {
    width: 100%;
}

.home_wrapper .skills_section {
    margin-top: -14rem;
}

.home_wrapper .intro_section {
    padding: 9rem 18rem 20rem 9rem;
}

.home_wrapper .skills_section {
    padding: 5rem 6rem;
}

.home_wrapper .intro_section .intro_text_wrapper .title h1 {
    font-size: 2rem;
}

.home_wrapper .intro_section .intro_text_wrapper .intro_text p {
    font-size: 1.2rem;
}

@media screen and (min-width: 1408px) {
    .container:not(.is-max-desktop):not(.is-max-widescreen) {
        max-width: 1344px;
    }
}

.home_wrapper .skills_section {
    width: 100%;
    margin-top: -14rem;
}


.home_wrapper .intro_section {
    padding: 9rem 12rem 18rem 12rem;
}

.home_wrapper .skills_section {
    padding: 5rem 6rem;
}

.home_wrapper .intro_section .intro_text_wrapper .title h1 {
    font-size: 2rem;
}

.home_wrapper .intro_section .intro_text_wrapper .intro_text p {
    font-size: 1.2rem;
}


@media screen and (min-width: 1408px) {
    .container:not(.is-max-desktop):not(.is-max-widescreen) {
        max-width: 1344px;
    }
}

.home_wrapper .skills_section .skills_wrapper {
    background-color: #ffffff;
    border-radius: 6px;
    box-shadow: 0 5px 5px 0 rgba(233, 240, 243, 0.5), 0 0 0 1px #E6ECF8;
    color: #141c3a;
    display: block;
    padding: 1.25rem;
    width: 100%;
}

.home_wrapper .skills_section .skills_wrapper .skills_item_container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.home_wrapper .skills_section .skills_wrapper .skills_item_container .skills_item {
    padding: 50px 30px;
    border-bottom: 1px solid #E6ECF8;
    border-right: 1px solid #E6ECF8;
    display: block;
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
}

.home_wrapper .skills_section .skills_wrapper .skills_item_container .skills_item .icon_container {
    background: #5BE9B9;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.home_wrapper .skills_section .skills_wrapper .skills_item_container .skills_item .icon_container .icon {
    color: white;
    font-size: 28px;
}

.home_wrapper .skills_section .skills_wrapper .skills_item_container .skills_item .intro {
    text-align: center;
}

.home_wrapper .skills_section .skills_wrapper .skills_item_container .skills_item .intro h1 {
    font-weight: 800;
    color: #141c3a;
    font-size: 1.5rem;
    margin: 1rem 0;
}

.home_wrapper .skills_section .skills_wrapper .skills_item_container .skills_item .intro p {
    font-size: 17px;
    line-height: 1.5;
    margin-bottom: 2em;
    margin-top: 1.5rem;
}

/* New Service Section Styles */
.home_wrapper .skills_section .skills_wrapper .skills_item_container .skills_item .service_section {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    margin-top: 2rem;
}

.home_wrapper .skills_section .skills_wrapper .skills_item_container .skills_item .service_section .service_item {
    padding-top: 2rem;
    text-align: center;
    border-top: 1px solid #E6ECF8;
}

.home_wrapper .skills_section .skills_wrapper .skills_item_container .skills_item .service_section .service_item .icon_container {
    background: #FFD700;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin: 0 auto 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.home_wrapper .skills_section .skills_wrapper .skills_item_container .skills_item .service_section .service_item h2 {
    font-size: 1.3rem;
    font-weight: 700;
    color: #141c3a;
    margin-top: 1rem;
}

.home_wrapper .skills_section .skills_wrapper .skills_item_container .skills_item .service_section .service_item p {
    font-size: 16px;
    line-height: 1.5;
    margin: 1rem 0;
}

.home_wrapper .skills_section .skills_wrapper .skills_item_container .skills_item .tech_stack {
    text-align: center;
}

.home_wrapper .skills_section .skills_wrapper .skills_item_container .skills_item .tech_stack .tech_title p {
    color: #6E07F3;
    margin-top: 50px;
    margin-bottom: 8px;
}

.home_wrapper .skills_section .skills_wrapper .skills_item_container .skills_item .tech_stack p {
    font-size: 16px;
}

.home_wrapper .skills_section .skills_wrapper .skills_item_container .skills_item .tech_stack .tech_listing li {
    margin-top: 0.25em;
}

.home_wrapper .projects {
    padding: 3rem 1.5rem;
}

@media screen and (min-width: 1024px) {
    .home_wrapper .projects {
        padding: 5rem 2rem 4.5rem 4.5rem;
    }
}

.home_wrapper .section_title {
    text-align: center;
    width: 100%;
}

.home_wrapper .section_title p {
    margin-top: 20px;
    font-size: 18px;
}

.home_wrapper .section_title p a {
    color: var(--theme-color);
}

.home_wrapper .projects .projects_rows_container {
    padding: 2rem 2rem 0rem 2rem;
}

@media screen and (min-width: 769px) {
    .home_wrapper .projects .projects_rows_container {
        padding-top: 4rem;
    }
}


.home_wrapper .projects .projects_rows_container .projects_row {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.home_wrapper .projects .projects_rows_container .projects_row .projects_item {
    padding: 0.75rem;
}

@media screen and (min-width: 768px) {
    .home_wrapper .projects .projects_rows_container .projects_row .projects_item {
        width: 50%;
    }
}

@media screen and (min-width: 1024px) {
    .home_wrapper .projects .projects_rows_container .projects_row .projects_item {
        width: 33%;
    }
}

.home_wrapper .projects .projects_rows_container .projects_row .projects_item figure {
    position: relative;
    overflow: hidden;
    border-radius: 12px;
}

.home_wrapper .projects .projects_rows_container .projects_row .projects_item figure img.project_thumb {
    -webkit-transform: scale(1);
    transform: scale(1);
    transition: .85s ease-in-out;
}

.home_wrapper .projects .projects_rows_container .projects_row .projects_item figure img {
    width: 100%;
    height: 100%;
    opacity: 1;
}

.home_wrapper .projects .projects_rows_container .projects_row .projects_item figure:hover img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.home_wrapper .projects .projects_rows_container .projects_row .projects_item figure figcaption {
    display: flex;
    flex-direction: column;
    padding: 0rem 3rem;
    color: #ffffff;
    z-index: 1;
    justify-content: center;
}

.home_wrapper .projects .projects_rows_container .projects_row .projects_item figure .overlay {
    background-color: #141c3a;
}

.home_wrapper .projects .projects_rows_container .projects_row .projects_item figure .overlay,
.home_wrapper .projects .projects_rows_container .projects_row .projects_item figure figcaption {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: .45s ease-in-out;
}

.home_wrapper .projects .projects_rows_container .projects_row .projects_item figure figcaption a {
    margin: 0 auto;
    font-size: 18px;
    padding: 1.2rem;
    font-weight: 400;
    color: #ffffff;
    background: transparent;
    border: 2px solid var(--theme-color);
    border-radius: 9999px;
}

.home_wrapper .projects .projects_rows_container .projects_row .projects_item figure figcaption h1 {
    color: #ffffff;
    margin-bottom: 1.8rem;
    font-weight: 500;
    text-align: center;
    font-size: 1.2rem;
}

@media screen and (min-width: 1216px) {

    .home_wrapper .projects .projects_rows_container .projects_row .projects_item figure figcaption h1 {
        font-size: 1.5rem;
    }
}

.home_wrapper .projects .projects_rows_container .projects_row .projects_item figure figcaption a:hover {
    background: var(--theme-color);
}

.home_wrapper .projects .projects_rows_container .projects_row .projects_item figure:hover {
    cursor: pointer;
}

.home_wrapper .projects .projects_rows_container .projects_row .projects_item figure:hover .project_thumb {
    opacity: 0;
}

.home_wrapper .projects .projects_rows_container .projects_row .projects_item figure:hover .overlay,
.home_wrapper .projects .projects_rows_container .projects_row .projects_item figure:hover figcaption {
    opacity: 1;
}

.home_wrapper .collaboration_section {
    padding: 3rem 1.5rem;
}

@media screen and (min-width: 1024px) {
    .home_wrapper .collaboration_section {
        padding: 5rem 1.5rem;
    }
}

.home_wrapper .collaboration_section .section_title {
    margin-bottom: 30px;
}

.home_wrapper .collaboration_section a {
    color: var(--theme-color);
    background: transparent;
    padding: 12px 18px;
    border: 2px solid var(--theme-color);
    border-radius: 9999px;
    color: var(--theme-color);
    display: flex;
    align-items: center;
    transition: all .5s ease-in-out;
    font-size: 18px;
}

.home_wrapper .collaboration_section a span {
    display: inline-block;
}

.home_wrapper .collaboration_section a .chat_icon {
    margin-bottom: -4px;
    margin-right: 6px;
}

.home_wrapper .collaboration_section a:hover {
    background: var(--theme-color);
    color: white;
}

.home_wrapper .testimonials_section {
    padding: 3rem 1.5rem;
}

@media screen and (min-width: 1024px) {
    .home_wrapper .testimonials_section {
        padding: 5rem 1.5rem 9rem 1.5rem;
    }
}

.home_wrapper .testimonials_section .testimonial_slider {
    position: relative;
    overflow: hidden;
    width: 100%;
    max-width: 700px;
    margin: auto;
}

.home_wrapper .testimonials_section .testimonial_slider .testimonial_wrapper {
    display: flex;
    transition: transform 0.5s ease-in-out;
    width: 100%;
}

.home_wrapper .testimonials_section .testimonial_slider .testimonial_item {
    min-width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;
}

.home_wrapper .testimonials_section .testimonial_slider .testimonial_item .avatar img {
    max-width: 96px;
    border-radius: 100px;
    height: auto;
}

.home_wrapper .testimonials_section .testimonial_slider .testimonial_item .statement {
    margin-top: 30px;
}

.home_wrapper .testimonials_section .testimonial_slider .testimonial_item .statement p {
    font-size: 20px;
    max-width: 700px;
    margin: 0 auto 2.5rem;
    text-align: center;
    font-family: "eurostile", sans-serif;
}

@media screen and (min-width: 769px) {
    .home_wrapper .testimonials_section .testimonial_slider .testimonial_item .statement p {
        font-size: 25px;
    }
}

.home_wrapper .testimonials_section .testimonial_slider .testimonial_item .description {
    margin-top: 20px;
}

.home_wrapper .testimonials_section .testimonial_slider .testimonial_item .description .client_name {
    color: #141c3a;
    font-weight: 800;
    font-size: 1.25rem;
    margin-bottom: 10px;
    font-family: "eurostile", sans-serif;
    text-align: center;
}

.home_wrapper .testimonials_section .testimonial_slider .testimonial_item .description .designation {
    color: #141c3a;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.25;
    text-align: center;
}

.home_wrapper .testimonials_section .testimonial_slider .dots {
    display: flex;
    gap: 8px;
    margin-top: 20px;
    width: 100%;
    justify-content: center;
}

.home_wrapper .testimonials_section .testimonial_slider .dots .dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: lightgrey;
    cursor: pointer;
}

.home_wrapper .testimonials_section .testimonial_slider .dots .dot.active {
    background-color: var(--theme-color);
}

.home_wrapper .calltoaction_section {
    padding: 3rem 1.5rem;
    background: var(--theme-color);

}

@media screen and (min-width: 1024px) {
    .home_wrapper .calltoaction_section {
        padding: 5rem 1.5rem;
    }
}

.home_wrapper .calltoaction_section .container {
    max-width: 1300px;
    width: auto;
    margin: 0 auto;
}


.home_wrapper .calltoaction_section .container .box {
    width: 100%;
    padding: 3.5rem 2rem;
    margin-top: -9rem;
    background-color: #141c3a;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2), 0 0 0 1px #141c3a;
    border-radius: 12px;
}


.home_wrapper .calltoaction_section .container .box .row {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-evenly;
}

.home_wrapper .calltoaction_section .container .box .row .item {
    color: #ffffff;
    margin-bottom: 0;
}

.home_wrapper .calltoaction_section .container .box .row .item p {
    max-width: 70%;
    text-align: center;
    margin: 0 auto;
    line-height: 1.6;
}

@media screen and (min-width: 1024px) {
    .home_wrapper .calltoaction_section .container .box .row .item p {
        font-size: 18px;
    }

    .home_wrapper .calltoaction_section .container .box .row .item a {
        font-size: 17px;
    }
}

.home_wrapper .calltoaction_section .container .box .row .item a {
    display: inline-flex;
    align-items: center;
    color: #ffffff;
    padding: 1.25em 2em;
    font-weight: 600;
    border: 2px solid #5BE9B9;
    border-radius: 9999px;
    transition: all .2s ease-in-out;
}

.home_wrapper .calltoaction_section .container .box .row .item a:hover {
    background-color: #5BE9B9;
    border-color: #5BE9B9;
    color: rgba(0, 0, 0, 0.7);
}

.home_wrapper .calltoaction_section .container .box .row .item a .hand_icon {
    margin-right: 5px;
}

@media screen and (max-width: 1300px) {
    .home_wrapper .calltoaction_section .container .box .row {
        flex-wrap: wrap;
        gap: 20px;
        justify-content: center;
        flex-direction: column;
    }

    .home_wrapper .calltoaction_section .container .box .row .item a {
        padding: 16px 30px;
        min-width: fit-content;
    }

    .home_wrapper .intro_section {
        padding: 5rem 8rem 15rem 8rem;
    }
}

@media screen and (max-width: 1100px) {
    .home_wrapper .skills_section {
        padding: 4rem 2rem;
    }

    .home_wrapper .testimonials_section {
        padding: 5rem 1.5rem 9rem 1.5rem;
    }
}

@media screen and (max-width: 900px) {
    .home_wrapper .banner_section .banner_tagline h1 {
        font-size: 36px;
    }

    .home_wrapper .banner_section .banner_tagline p {
        font-size: 20px;
    }

    .home_wrapper .banner_section .avatar_container img {
        max-width: 190px;
    }

    .home_wrapper .banner_section .banner_image_container img {
        max-width: 600px;
    }

    .home_wrapper .skills_section .skills_wrapper .skills_item_container .skills_item .service_section {
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 768px) {
    .home_wrapper h1 {
        font-size: 30px;
    }

    .home_wrapper .banner_section .banner_tagline {
        margin-top: 0px;
    }

    .home_wrapper .banner_section .banner_tagline p {
        font-size: 18px;
    }

    .home_wrapper .banner_section .avatar_container img  {
        max-width: 150px;
    }

    .home_wrapper .banner_section .banner_image_container img {
        max-width: 500px;
    }

    .home_wrapper .intro_section .intro_text_wrapper {
        max-width: 98%;
    }

    .home_wrapper .projects .projects_rows_container .projects_row .projects_item figure figcaption a  {
        padding: 10px 16px;
        font-size: 16px;
    }

    .home_wrapper .projects .projects_rows_container .projects_row .projects_item figure figcaption h1  {
        font-size: 16px;
    }

    .home_wrapper .calltoaction_section .container .box {
        padding: 3rem 2rem;
    }

    .home_wrapper .calltoaction_section .container .box .row {
        gap: 30px;
    }
}

@media screen and (max-width: 550px) {
    .home_wrapper h1 {
        font-size: 28px !important;
    }

    .home_wrapper .banner_section .avatar_container img {
        max-width: 150px;
    }

    .home_wrapper .banner_section .banner_image_container img {
        max-width: 400px;
    }

    .home_wrapper .intro_section {
        padding: 5rem 4rem 15rem 4rem;
    }

    .home_wrapper .intro_section .intro_text_wrapper {
        max-width: 100%;
    }
}

@media screen  and (max-width: 400px){
    .home_wrapper h1 {
        font-size: 26px !important;
    }

    .home_wrapper .banner_section .avatar_container img {
        max-width: 120px;
    }

    .home_wrapper .banner_section .banner_image_container img {
        max-width: 300px;
    }

    .home_wrapper .intro_section {
        padding: 5rem 1rem 15rem 1rem;
    }

    .home_wrapper .intro_section .intro_text_wrapper .intro_text p {
        font-size: 17px;
    }

    .home_wrapper .skills_section .skills_wrapper .skills_item_container .skills_item {
        padding: 10px 12px;
    }

    .home_wrapper .calltoaction_section .container .box {
        padding: 2rem 0rem;
    }    

    footer .footer_wrapper .top_footer p {
        font-size: 18px;
    }

    .home_wrapper .collaboration_section a {
        font-size: 16px;
    }

    .home_wrapper .calltoaction_section .container .box .row .item a {
        padding: 12px 18px;
    }

    .home_wrapper .section_title p {
        font-size: 17px;
    }

    .home_wrapper .projects .projects_rows_container .projects_row .projects_item figure figcaption {
        padding: 0rem 1rem;
    }

    .home_wrapper .projects .projects_rows_container .projects_row .projects_item figure figcaption h1 {
        font-size: 16px !important;
    }

    .home_wrapper .projects .projects_rows_container .projects_row .projects_item figure figcaption a {
        padding: 8px 16px;
    }
}